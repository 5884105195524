import * as React from "react"
import { useState } from 'react'
import { Link, graphql } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import Seo from '../components/seo'
import Footer from '../components/footer'
import Header from '../components/header'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import Reviews from '../components/reviews_category'
import ProductReviews from '../components/product_reviews'

const DogsPage = ({data}) => {
  const entry = data.prismicDogsPage

  const [isReviewsOpen, setReviewsOpen] = useState(false)
  const reviewsHandler = () => {
    setReviewsOpen(true)
    //console.log('reviewsHandler')
  }

  if (!entry) return null
  return (
    <>
      <ProductReviews isReviewsOpen={isReviewsOpen} setReviewsOpen={setReviewsOpen} />
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />

      <div className="w-full bg-orange rounded-br-[133px]">
        <div className="flex:none md:flex justify-between">
          <div className="w-full md:w-6/12 relative">
            <div className="absolute z-10 top-1/2 -left-16 transform -translate-y-1/2 origin-center -rotate-90">
              <button onClick={() => reviewsHandler()} className="rounded-full px-9 py-3 font-poppins text-lg font-medium bg-diamond text-white">
                <div className="flex flex-row items-center gap-3">
                  <div>Reviews</div>
                  <div className="text-lg">★</div>
                </div>
              </button>
            </div>
            <GatsbyImage className="relative w-full h-full block object-cover py-12 md:py-0" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} />
          </div>
          <div className="w-full md:w-6/12 px-16 py-16 md:py-36">
            <div className="leading-snug text-2xl text-white font-poppins font-semibold">
              <h2 className="mb-8">
                <span className="bg-white text-3xl text-diamond px-3 py-1 font-bold leading-tight inline-block">{entry.data.tag1}</span>
              </h2>
              <p className="ml-3">{entry.data.heading}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-16 bg-white relative">
        <div className="absolute z-10 right-0 bottom-12 md:right-12 bottom-24 opacity-10">
          <img className="w-36 h-24 md:w-60 md:h-48 -rotate-45" src="/img/paw_print.svg" alt="" />  
        </div>
        <div className="w-9/12 mx-auto max-w-screen-xl relative">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-16">
            {entry.data.entries.map((entry, i) => {
              return (
                <div key={i}>
                  <Link to={linkResolver(entry.page_link1)}>
                    <figure>
                      <GatsbyImage className="aspect-w-4 aspect-h-3 rounded-tr-[27px] rounded-tl-[27px] rounded-bl-[27px] w-full h-full block object-cover" image={entry.image1.gatsbyImageData} alt={entry.image1.alt ?? ''} />
                    </figure>
                    <div className="font-poppins text-orange font-semibold text-xl leading-tight mt-6">
                      <h3>{entry.heading1} &#62;</h3>
                    </div>
                    <div className="font-poppins font-medium text-sm text-diamond-80 mt-3 leading-snug">
                      <p>{entry.text1}</p>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className="w-full relative">
        <div className="absolute -inset-0">
          <GatsbyImage className="rounded-tl-[133px] w-full h-full object-cover block" image={entry.data.review_image.gatsbyImageData} alt={entry.data.review_image.alt ?? ''} />
        </div>
        
        <div className="absolute inset-0">
          <div className="flex items-end h-full">
            <div className="w-6/12">
              &nbsp;
            </div>
            <div className="w-4/12 h-full">
              <div className="w-full h-full block bg-gradient-to-l from-black to-transparent">
                &nbsp;
              </div>
            </div>
            <div className="w-2/12 h-full bg-black">
              &nbsp;
            </div>
          </div>
        </div>

        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex:none md:flex justify-between items-end relative">
            <div className="w-full md:w-6/12">
              &nbsp;
            </div>
            <div className="w-full md:w-6/12 pt-2 pb-8 md:py-16">
              <div className="flex justify-end mb-2 md:mb-8">
                <div className="font-poppins text-xl md:text-3xl text-white font-semibold text-center">
                  We love all our
                  <br />
                  <span className="bg-white text-orange px-2 py-1 font-bold">{entry.data.review_number}</span>
                  <br />
                  furry L&amp;C friends!
                </div>
              </div>
              <div className="flex justify-end text-right pt-2 md:pt-8">
                <div className="font-poppins leading-tight text-xl md:text-2xl font-medium md:font-semibold">
                  <h2 className="text-white mb-1 md:mb-6">"<em>{entry.data.review_quote}</em>"</h2>
                  <p className="text-orange">- {entry.data.review_author}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="w-full bg-diamond">
        <div className="w-full py-16 bg-white rounded-br-[133px]">
          <div className="w-10/12 mx-auto max-w-screen-xl">
            <div className="flex-none md:flex justify-between gap-16">
              <div className="w-full md:w-6/12">
                <GatsbyImage className="object-scale-down w-full h-full block" image={entry.data.image2.gatsbyImageData} alt={entry.data.image2.alt ?? ''} />
              </div>
              <div className="w-full md:w-6/12 pt-24 md:pt-0 text-center md:text-left">
                <h3 className="font-poppins bg-purple px-3 py-1 text-white font-poppins font-bold text-xl mb-6 inline-block">{entry.data.tag2}</h3>
                <h2 className="font-poppins text-diamond font-semibold text-2xl md:text-3xl mb-6 leading-tight">{entry.data.heading2}</h2>
                <div className="prose font-poppins prose-h2:font-semibold prose-p:font-medium leading-snug">
                  <PrismicRichText linkResolver={linkResolver} field={entry.data.text2.richText} />
                </div>
                <Link to={linkResolver(entry.data.page_link2)} className="font-poppins text-diamond-80 text-lg text-orange visited:text-orange mt-6 inline-block">
                  Learn more &gt;
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Reviews />
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicDogsPage {
    data {
      page_title
      meta_description
      tag1
      heading
      image {
        alt
        gatsbyImageData
      }
      entries {
        image1 {
          alt
          gatsbyImageData
        }
        heading1
        text1
        page_link1 {
          url
          uid
          type
          link_type
        }
      }
      review_image {
        alt
        gatsbyImageData
      }
      review_number
      review_quote
      review_author
      image2 {
        alt
        gatsbyImageData
      }
      tag2
      heading2
      text2 {
        richText
      }
      page_link2 {
        url
        uid
        type
        link_type
      }
    }
  }
}
`

export default DogsPage
